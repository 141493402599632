<template>
  <div>
    <v-card class="mt-4">
      <v-card-title>Overwrite Project Code</v-card-title>

      <v-container fluid>
        <v-form ref="assignProjectCode">
          <v-select
            label="Project Code To Assign"
            :items="projectCodes"
            :item-text="item => `${item.name} - ${item.code}`"
            item-value="code"
            v-model="dataToSend.projectCode"
            :rules="multiRules"
          />

          <v-textarea
            label="Enter Email, ONE PER LINE"
            v-model="emailsString"
            :rules="multiRules"
          >
          </v-textarea>
        </v-form>

        <v-btn class="primary" @click="assignProject">Overwrite Project Code</v-btn>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import RestResource from "./../../services/dataServiceMobile.js";

const service = new RestResource();
export default {
  components: {
  },
  data() {
    return {
      multiRules: [
        value => !!value || "Field is required.",
        value => (value || "").length > 0 || `Field is required`
      ],
      projectCodes: [],
      emailsString: "",
      dataToSend: {
        email: [],
        projectCode: null,
      }
    };
  },

  mounted() {
    service.fetchProjectCodes().then(res => {
      this.projectCodes = res.data
    })
  },

  methods: {
    assignProject() {
      if (this.$refs.assignProjectCode.validate()) {
        let emails = this.emailsString.split(/\r?\n/)

        if (emails.length > 0) {
          for (let i = 0; i < emails.length; i++) {
            if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emails[i]))) {
              this.$store.state.showDialogMessage = true
              this.$store.state.dialogMessageTitle = "Error"
              this.$store.state.dialogMessageText = `Not a valid email - ${emails[i]}`

              break
            }
          }

          this.dataToSend.emails = emails.map(e => { return e.toLowerCase() })

          this.$setLoader()
          service.assignProjectCode(this.dataToSend).then(res => {
            this.$disableLoader()
            this.$showSuccessMessage(res.data)
          })
        }
      }
    }

  }
};
</script>